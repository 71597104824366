@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');

html {
  overscroll-behavior-y: none;
}

body {
  margin: 0 10px;
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 20px;
  color: #282828;
  background-color: #ededf7;
}

body * {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {
  color: rgb(69, 69, 150)
}
a:hover {
  color: inherit;
}
@media (hover:hover) {
  a:hover {
    color: rgb(69, 69, 150, 0.7)
  }
}
